<template>
    <div>
        <b-card :title="$t('home.text_1')">
            {{$t('home.text_2')}}
        </b-card>
    </div>
</template>

<script>
    import { BCard } from 'bootstrap-vue'
    export default {
        components: {
            BCard
        }
    }
</script>
